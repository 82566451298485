.contAddExp {
    width: 90%;
    background-color: rgb(224, 224, 224);
    padding: 5%;
    padding-top: 3%;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.contInputExp {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.redButtonExp {
    width: 100%;
    height: 30px;
    border:none;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    background-color: white;
    font-size: 14px;
}
.contInputExp input {
    width: 70%;
    height: 25px;
    border-radius: 5px;
    border: none;
    padding-left: 10px;
}
.contInputExp input:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}