.App {
  text-align: center;
}
.headBlock {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 55px;
  right: 3%;
  width:90px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(207, 207, 207);
  opacity: 0.9;
}
.btnHome {
  width: 70px;
  height: 45px;
  /* position: fixed;
  top: 45px;
  right: 3%; */
  margin-bottom: 10px;
  opacity: 0.9;
  background-color: rgb(205, 203, 207);
  border-radius: 5px;
  /* border: solid 1px rgb(189, 185, 185); */
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
}
.btnHome:hover {
  opacity: 0.7;
}
.btnExit {
  width: 70px;
  height: 30px;
  /* position: fixed;
  top: 100px; */
  margin-bottom: 10px;
  right: 3%;
  opacity: 0.9;
  background-color: orange;
  border-radius: 5px;
  /* border: solid 1px rgb(189, 185, 185); */
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
}
.btnExit:hover {
  opacity: 0.7;
}



