
.codeCont {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-bottom: 20px; */
}
.inpCont{
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.btnCourse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 30px;
    border-radius: 5px;
    border: 1px grey solid;
    box-sizing: border-box;
    margin-left: 20px;
    cursor: pointer;
    background-color: rgb(255, 187, 187);
    color: black;
}
.btnCourse:hover {
    opacity: 0.8;
}
.searchInputCheck {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px grey solid;
    background-color: rgb(184, 255, 222);
}
.searchCheck {
    width: 20px;
    height: 20px;
}
.inputInp {
    width: 600px;
    box-sizing: border-box;
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 17px;
    border: 1px solid grey;
    border-radius: 5px;
    outline: none; 
    margin-bottom: 20px;
}
.inputInp:focus {
    outline: none; /* Убираем стандартную обводку */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); /* Создаем тень */
  }
.hCode {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

