.tableExp {
    border-collapse: separate;
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); */
    border-radius: 5px;
    width: 100%;
    /* margin-top: 40px; */
  }
  thead {
    background-color: #a7acd1;
  }
