.contTotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
    padding: 5px;
}
.contTotal p {
    margin: 0;
    margin-left: 5px;
}
.styleIcom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.contTotalIc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 48%;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
    padding: 5px;
}
.contTotalIc p {
    margin: 0;
    margin-left: 5px;
}
.totalCont {
    width: 95%;
    box-sizing: border-box;
    padding: 3%;
    padding-top: 2%;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 20px;
    background-color: rgb(235, 235, 235);
}
