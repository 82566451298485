
.contHome {
    width: 100%;
    min-width: 1010px;
    height: 50px;
    background-color: rgb(207, 197, 181);
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2); */
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.headText {
    color: white;
    font-size: 22px;
}
.addBike {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20px;
    margin-left: 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: white;
    padding: 5px;
    text-decoration: none;
    color: black;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.addBike:hover{
    opacity: 0.8;
}
.addBike:hover{
    opacity: 0.7;
}

/* @media (max-width: 450px) {
    .contHome {
        flex-wrap: wrap;
        height: 90px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;
    }
    .addBike {
        margin-left: 5px;
    }

} */