
input, textarea, select {
    font-size: 16px;
  }
  .contEnter {
      width: 100%;
      margin: 0 auto ;
      text-align: center;
      background-color: white;
      padding-top: 20px;
  }
  .contInputEnt {
      width: 90%;
      max-width: 500px;
      background-color: white;
      margin: auto;
      padding: 10px;
      border-radius: 5px;
      padding-bottom: 20px;
      box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2);
  }
  .textEnt1 {
      color:#116466
  }
  .textEnt2 {
      color:#116466;
  }
  .textBottomEnt {
      color: grey;
  }
  .textLabelEnt {
      text-align: start;
      padding-left: 15px;
  }
  .inpEnt {
      width: 90%;
      height: 30px;
  }
  .inpEnt:focus{
      outline: none;
  }
  
  .contCheckEnt {
      display:flex;
      align-items: center;
      margin: auto;
      margin-top: 30px;
      width: 90%;
      height: 50px;
      border-top: 1px solid #747474;
      border-bottom: 1px solid #747474;
      justify-content:space-between;
  }
  
  .regButtonEnt {
      width: 90%;
      height: 35px;
      background-color: #116466;
      color: white;
      font-size: 16px;
      border: solid 1px #116466;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 30px;
  }
  .regButtonEnt:hover {
      opacity: 0.7;
  }
  .warningEnt {
      text-align: start;
      font-size: 12px;
      color: red;
  }
  