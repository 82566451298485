.contHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5%;
    background-color: grey;
    width: 100%;
    position: fixed;
    top:0px

}
.contRed {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 1010px;
    box-sizing: border-box;
    padding: 5%;
    padding-top: 30px;
    background-color: rgb(235, 235, 235);
}

.informShow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    margin-right: 20px;
    padding: 20px;
}
.contRent {
    width: 90%;
    padding: 5%;
    padding-top: 5%;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);

}
.redAddButton {
    width: 100%;
    height: 30px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    background-color: white;
    font-size: 14px;
}
.contInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3); */
    border: 0.5px solid grey;
    background-color: rgba(209, 209, 209, 0.7);
    padding: 5px;
    align-items: center;
    margin-bottom: 7px;
    border-radius: 5px;
}
.contInput p {
    margin: 0;
}
.contInput input {
    width: 70%;
    height: 25px;
    border-radius: 5px;
    border: none;
    padding-left: 10px;
    margin: 0;
    text-align: center;
}
.contInput textarea {
    width: 70%;
    height: 50px;
    border-radius: 5px;
    border: none;
    padding-left: 10px;
    margin: 0;
    /* text-align: center; */
}
.contInput input:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}
.contInput textarea:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}
.contInputCheck {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
}
.contInputCheck input {
    width: 20px;
    height: 20px;
    border-radius: 7px;
    border: none;
    padding-left: 10px;
}
.contInputCheck input:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}
.buttonBack {
    margin-left: 15px;
    margin-top: 10px;
    width: 70px;
    height: 25px;
    border: 0.5px solid grey;
    border-radius: 5px;
    
}