.contRedAdd {
    padding: 5%;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-bottom: 20px;
}
.contAddBike {
    width: 50%;
    min-width: 450px;
    background-color: rgb(224, 224, 224);
    padding: 5%;
    padding-top: 3%;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    
}
.linkPrice{
    /* padding: 2px;
    padding-left: 8px;
    padding-right: 8px; */
    width: 90px;
    height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); */
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    /* margin-bottom: 5px; */
    border: 2px black solid;
    color: black;
    background-color: rgb(162, 173, 236);
    margin-bottom: 5px;
}

.linkPrice:hover{
    opacity: 0.8;
}
.redButton {
    width: 100%;
    height: 30px;
    border:none;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    background-color: white;
    font-size: 14px;
}
.contInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.contInput input {
    width: 70%;
    height: 25px;
    border-radius: 5px;
    border: none;
    padding-left: 10px;
}
.contInput input:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}