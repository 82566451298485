.contModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: grey;
    opacity: 0.9;

}
.contText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    background-color: white;
    border-radius: 35px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}
.btnDel {
    width: 60%;
    height: 30px;
    border-radius: 15px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
}
.btnDel:hover {
    opacity: 0.5;
}
