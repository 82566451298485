.redAddButton {
    width: 100%;
    height: 30px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    background-color: white;
    font-size: 14px;
}
.img {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.btnImg {
    width: 100%;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    height: 20px;
    font-size: 10px;
    background-color: rgb(218, 162, 215);
}
.btnImg:hover {
    opacity: 0.7;
}
.btnImgSave {
    border: none;
    margin-top: 10px;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* font-size: 15px; */
    background-color: #b9b9fa;
}
.btnImgSave:hover {
    opacity: 0.7;
}