
.contListBikes {
  display: flex;
  flex-direction: column;
  width: 95%;
  min-width: 1010px;
  padding: 2%;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  margin: 20px;
  

}
.markCompany {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: yellow;
  position: absolute;
  top:20px
}
.table {
    border-collapse: separate;
    border-spacing: 3px 3px;
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); */
    border-radius: 5px;
    width: 100%; /* Занимает всю ширину контейнера */
    table-layout: auto; /* Автоматически подстраивает ширину столбцов */
    
  }

  th, td {
    /* border: 1px solid rgb(151, 151, 151); */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 8px;
    text-align: center;
  }

