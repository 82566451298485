.contHeadCount {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5%;
    background-color: grey;
}
.btnAllRes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 35px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px grey solid;
  cursor: pointer;
}
.buttonBackCount {
    margin-left: 15px;
    margin-top: 10px;
    width: 70px;
    height: 25px;
    border: 0.5px solid grey;
    border-radius: 5px;
    cursor: pointer;
}
.contCount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-start;
    width: 95%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgb(235, 235, 235);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    margin: auto;
    padding: 2%;
    margin-top: 20px;
}
.inpPrice {
  outline: none;
  border: none;
}
.contTable1 {
    margin: auto;
    margin-top: 0;
    width: 40%;
    /* margin-right: 40px; */
}
.contTable2 {
    width: 60%;
    margin-left: 40px;

}
.tableCount {
    border-collapse: separate;
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); */
    border-radius: 5px;
    width: 100%;
  }
  
  th, td {
    /* border: 1px solid rgb(151, 151, 151); */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 8px;
    text-align: center;
  }

  .tableCount2 {
    border-collapse: separate;
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); */
    border-radius: 5px;
    width: 100%;
  }
  th, td {
    border: 1px solid rgb(151, 151, 151);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 8px;
    text-align: center;
    height: auto;
  }